import Autocomplete from '@material-ui/lab/Autocomplete';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Chip,
  FormControl,
  OutlinedInput,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Stack,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions
} from '@mui/material';
import { toast } from 'react-toastify';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import IClient from '../clients/IClient';
import { IGroup, IUser } from '../../../../interfaces/interfaces';
import { useDispatch } from 'react-redux';
import { createGroup } from '../../../../redux/actions/groups';
import Clear from '@material-ui/icons/Clear';
import { Colors, UserStatus } from '../../../../enums/enums';
import api from '../../../../services/api';
import { ClientActionTypes } from '../../../../redux/actions/clients';

interface Props {
  clients: IClient[];
  users: IUser[];
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
}

const CreateCohort = ({ clients, users, open, onClose }: Props) => {
  const activeUsers = users?.filter((user) => user.status === UserStatus.Active);
  const dispatch = useDispatch();
  const [client, setClient] = useState(null);
  const [leaderId, setLeaderId] = useState(null);
  const [coLeaderId, setCoLeaderId] = useState(null);
  const [sortedUsers, setSortedUsers] = useState([]);
  const [descriptionLeaderName, setDescriptionLeaderName] = useState('');
  const [descriptionParticipantName, setDescriptionParticipantName] = useState('');
  const ITEM_HEIGHT = 60;
  const ITEM_PADDING_TOP = 10;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  useEffect(() => {
    if (client) {
      const usersByClient = activeUsers.filter(
        (user) => user.clientId === client.id && user.id !== leaderId && user.id !== coLeaderId
      );
      setSortedUsers(usersByClient);
    }
  }, [client, leaderId, coLeaderId]);

  let finalDescription = descriptionLeaderName + descriptionParticipantName;

  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      client: null,
      leaderId: null,
      coLeaderId: null,
      participants: [],
      description: null
    }
  });

  const validateName = async (cohortName: string): Promise<boolean> => {
    let response = null;
    await api.get(`/groups/validate-name/${cohortName}`).then(({ data }) => {
      response = data;
    });
    return response;
  };

  const onSubmit = async () => {
    const values = getValues();
    const participantIdsArr = values.participants?.map((user) => user.id);
    const group: IGroup = {
      description: `${finalDescription}${participantIdsArr?.length > 1 ? '-' + participantIdsArr?.length : ''}`,
      leaderId: values.leaderId.id,
      coLeaderId: values.coLeaderId ? values.coLeaderId.id : null,
      participantIds: participantIdsArr
    };
    // to avoid having cohorts with duplicated names
    const nameExists = await validateName(group?.description);

    if (!nameExists) {
      try {
        dispatch(createGroup(group));
        reset();
        setDescriptionLeaderName('');
        setDescriptionParticipantName('');
        onClose(false);
      } catch (error) {
        toast.error(`Error creating group.\nMessage: ${error?.message}`);
      }
    } else {
      return toast.error(`Cohort named: ${finalDescription} already exists. Please rename.`);
    }
  };

  const options = activeUsers?.map((option) => {
    const clientName = clients?.find((client) => client.id === option.clientId)?.name?.toUpperCase();
    return {
      clientName: clientName,
      ...option
    };
  });

  return (
    <Box>
      <Dialog maxWidth="lg" onClose={() => onClose(false)} open={open}>
        <DialogTitle
          sx={{ backgroundColor: Colors.BackgroundDrawer, color: Colors.TextElevated }}
          id="simple-dialog-title"
        >
          Create Cohort
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent sx={{ backgroundColor: Colors.BackgroundMain }}>
            <Stack direction="column" spacing={4}>
              <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
                <Controller
                  name="leaderId"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      id="leaderId"
                      onChange={(
                        event,
                        newInputValue: {
                          id: string;
                          name: string;
                          company: string;
                          clientName: string;
                        }
                      ) => {
                        field.onChange(newInputValue);
                        if (newInputValue?.name) {
                          setDescriptionLeaderName(newInputValue?.name);
                        }
                        setLeaderId(newInputValue?.id);
                      }}
                      options={options.sort((a, b) => -b.clientName?.localeCompare(a.clientName))}
                      groupBy={(option) => option.clientName}
                      getOptionLabel={(option: { id: string; name: string; company: string; clientName: string }) =>
                        `${option.name} ${
                          option.company?.toUpperCase() === option.clientName
                            ? ''
                            : option.company === null
                            ? ' - [add company info]'
                            : ` - ${option.company}`
                        }`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            shrink: true
                          }}
                          sx={{ width: '400px', color: Colors.TextElevated }}
                          label="Leader"
                          variant="outlined"
                          color="success"
                          required={true}
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name="coLeaderId"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="coLeader"
                      onChange={(event, newInputValue) => {
                        field.onChange(newInputValue);
                        setCoLeaderId(newInputValue?.id);
                      }}
                      options={options
                        .filter((option) => option.id !== leaderId)
                        .sort((a, b) => -b.clientName?.localeCompare(a.clientName))}
                      groupBy={(option) => option.clientName}
                      getOptionLabel={(option: { id: string; name: string; company: string; clientName: string }) =>
                        `${option.name} - ${option.company || '[add company name]'}`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            shrink: true
                          }}
                          sx={{ width: '400px' }}
                          label="Co-Leader"
                          variant="outlined"
                          color="success"
                        />
                      )}
                    />
                  )}
                />
              </Stack>

              <Stack direction="row" spacing={2} alignItems="flex-start" sx={{ mb: 4 }}>
                <Controller
                  name="client"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="clients"
                      onChange={(event, newInputValue: { id: string; name: string }) => {
                        field.onChange(newInputValue);
                        setClient(newInputValue);
                      }}
                      options={clients}
                      getOptionLabel={(option: { name: string }) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            shrink: true
                          }}
                          sx={{ width: '300px' }}
                          label="Client"
                          variant="outlined"
                          color="success"
                        />
                      )}
                    />
                  )}
                />

                <Controller
                  name="participants"
                  control={control}
                  render={({ field }) => (
                    <FormControl variant="outlined">
                      <InputLabel shrink id="user-label-id">
                        Participants *
                      </InputLabel>
                      <Select
                        labelId="user-label-id"
                        multiple
                        color="success"
                        sx={{ width: '500px', minHeight: '60px' }}
                        notched={true}
                        required={true}
                        input={<OutlinedInput id="user-label-id" label="Participants" />}
                        {...field}
                        onChange={(event, addedUser: any) => {
                          const addedUserId = addedUser.props?.value?.id;
                          if (!getValues('participants')?.find((user) => user.id === addedUserId)) {
                            field.onChange(event);
                          }
                          if (getValues('participants')?.length === 1) {
                            setDescriptionParticipantName('-' + addedUser.props?.value?.name);
                          } else {
                            setDescriptionParticipantName('');
                          }
                        }}
                        renderValue={(selectedUsers) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selectedUsers.map((user) => (
                              <Chip
                                key={user.id}
                                label={user.name}
                                onDelete={() => {
                                  const filterUsers = getValues('participants')?.filter((u) => u.id !== user.id);
                                  setValue('participants', filterUsers);
                                  if (filterUsers?.length === 1) {
                                    setDescriptionParticipantName('-' + filterUsers[0]?.name);
                                  }
                                }}
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {sortedUsers?.map((user) => (
                          <MenuItem key={user.id} value={user}>
                            {user.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Stack>

              <Stack direction="row" spacing={2} alignItems="flex-start" sx={{ mb: 3 }}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="description"
                      value={finalDescription}
                      InputLabelProps={{
                        shrink: true
                      }}
                      label="Cohort Name"
                      color="success"
                      variant="outlined"
                      onChange={(event) => {
                        field.onChange(event);
                        finalDescription = event.target.value;
                      }}
                      InputProps={{
                        endAdornment: <Clear onClick={() => field.onChange('')} style={{ cursor: 'pointer' }} />
                      }}
                      sx={{ width: '450px' }}
                      required={true}
                      helperText="Number of participants will be added to the Cohort name"
                    />
                  )}
                />
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ backgroundColor: Colors.BackgroundDrawer }}>
            <Button
              variant="contained"
              color="success"
              type="submit"
              sx={{ height: 55, backgroundColor: Colors.ButtonGreen, m: 2 }}
            >
              Create Cohort
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};
export default CreateCohort;