import * as React from 'react';
import api from '../../../../services/api';
import { Box, Button, Tooltip, Typography, Chip } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { useState } from 'react';
import { InputFileUpload } from '../../../shared/input-file-upload/InputFileUpload';
import { format, parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { deleteLearningTool, fetchLearningTools, getLearningTools } from '../../../../redux/actions/content';
import { ConfirmationDialog } from '../../../dialogs/ConfirmationDialog';
import { Colors } from '../../../../enums/enums';
import { toast } from 'react-toastify';
import UsagesModal from '../UsagesModal';
import EditLearningTool from './EditLearningTool';
import { fetchCurrentClients, getActiveClients } from '../../../../redux/actions/clients';
import { noImageUrl } from '../../../../constants/constants';

interface FileUploadResponse {
  id: string;
  filename: string;
  url: string;
  createdAt: string;
}

export default function LearningTools() {
  const dispatch = useDispatch();
  const uploadedLearningTools = useSelector(getLearningTools);
  const clients = useSelector(getActiveClients);
  const [tableData, setTableData] = useState([]);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [selectedFileId, setSelectedFileId] = React.useState(null);
  const [fileObject, setFileObject] = React.useState(null);
  const [openEditLearningTool, setOpenEditLearningTool] = React.useState(false);
  const [openUsagesModal, setOpenUsagesModal] = React.useState({ open: false, sessionList: [], filename: '' });

  React.useEffect(() => {
    dispatch(fetchLearningTools());
    dispatch(fetchCurrentClients());
  }, [dispatch]);

  React.useEffect(() => {
    setTableData(
      uploadedLearningTools?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    );
  }, [uploadedLearningTools]);

  const handleSelectedFile = (files: FileList): void => {
    if (files?.length) {
      const file = files.item(0);
      console.log('File selected', file);

      const formData = new FormData();
      formData.append('file', file);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      api.post<FileUploadResponse>(`/content/learning-tool`, formData, config).then(({ data }) => {
        dispatch(fetchLearningTools());
        console.log('API response on resource upload: ', data);
        toast.success('Successfully uploaded Resource file');
      });
    }
  };

  const handleDelete = () => {
    dispatch(deleteLearningTool(selectedFileId));
    setConfirmationDialogOpen(false);
  };

  const handleClose = () => {
    setOpenEditLearningTool(false);
    dispatch(fetchLearningTools());
  };

  const columns = [
    {
      name: 'id',
      options: {
        display: false
      }
    },
    {
      name: 'filename',
      label: 'File Name',
      options: {
        customBodyRender: (v, x) => {
          const value = x?.rowData[9];
          const k = value > 0 ? Math.floor(Math.log2(value) / 10) : 0;
          const rank = (k > 0 ? 'KMGT'[k - 1] : '') + 'b';
          const count = Math.floor(value / Math.pow(1024, k));
          // to not display the timestamp that is added to each file to avoid duplicated filenames
          const originalFileName = v?.split('-')?.slice(1)?.join('-');
          const originalFileNameWithoutDash = v?.replace(/^\d+/, '');

          return (
            <>
              <Typography sx={{ minWidth: '270px', mb: 1.5 }}>
                {originalFileName ? originalFileName : originalFileNameWithoutDash ? originalFileNameWithoutDash : v}
              </Typography>
              <Typography sx={{ ml: 2 }} fontSize="small">
                type: {x?.rowData[7]}
              </Typography>
              <Typography sx={{ ml: 2 }} fontSize="small">
                size: {count + rank}
              </Typography>
            </>
          );
        }
      }
    },
    {
      name: 'title',
      label: 'Title in Portal',
      options: {
        customBodyRender: (v, x) => <Typography sx={{ minWidth: '250px' }}>{v}</Typography>
      }
    },
    {
      name: 'clientIds',
      label: 'Visible to Clients:',
      options: {
        customBodyRender: (v, x) => {
          const resourceClients = clients?.filter((client) => v?.includes(client?.id));
          return (
            <ul style={{ marginLeft: -20 }}>
              {resourceClients?.length ? (
                resourceClients?.map((client) => (
                  <li key={client?.id}>
                    <Typography sx={{ width: '150px' }}>{client?.name}</Typography>
                  </li>
                ))
              ) : (
                <li key={1}>
                  <Typography sx={{ width: '150px' }}>All Clients</Typography>
                </li>
              )}
            </ul>
          );
        }
      }
    },
    {
      name: 'placement',
      label: 'Placement',
      options: {
        customBodyRender: (v, x) => <Typography sx={{ width: '150px', mx: -1 }}>{v}</Typography>
      }
    },
    {
      name: 'azureUrl',
      label: 'File URL',
      options: {
        customBodyRender: (v, x) => (
          <Tooltip title="View file" arrow placement="top">
            <a href={v} target="_blank">
              View File
            </a>
          </Tooltip>
        )
      }
    },
    {
      name: 'thumbnailUrl',
      label: 'Thumbnail',
      options: {
        customBodyRender: (v, x) => <img src={v || noImageUrl} style={{ width: '150px', height: '100px' }} />
      }
    },
    {
      name: 'mimeType',
      label: '',
      options: {
        display: false
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: (v, x) => (
          <Chip sx={{ backgroundColor: v === 'ACTIVE' ? Colors.Active : Colors.Inactive }} label={v} />
        )
      }
    },
    {
      name: 'size',
      label: 'Size',
      options: {
        display: false
      }
    },
    {
      name: 'createdAt',
      label: 'Uploaded',
      options: {
        sort: true,
        customBodyRender: (value) => {
          const parsedData = parseISO(value);
          return <Typography>{format(parsedData, 'MMM dd, yyyy').toLocaleString()}</Typography>;
        }
      }
    },
    {
      name: 'updatedAt',
      label: 'Updated',
      options: {
        sort: true,
        customBodyRender: (value) => {
          const parsedData = parseISO(value);
          return <Typography>{format(parsedData, 'MMM dd, yyyy').toLocaleString()}</Typography>;
        }
      }
    },
    {
      name: 'id',
      label: 'Edit',
      options: {
        customBodyRender: (value, x) => {
          const file = uploadedLearningTools?.find((file) => file.id === value);
          return (
            <Button
              variant="contained"
              color="success"
              sx={{ backgroundColor: Colors.TextElevated }}
              onClick={() => {
                setOpenEditLearningTool(true);
                setFileObject(file);
              }}
            >
              Edit
            </Button>
          );
        }
      }
    },
    {
      name: 'id',
      label: 'Delete',
      options: {
        customBodyRender: (value, x) => {
          const handleClick = () => {
            setSelectedFileId(value);
            setConfirmationDialogOpen(true);
          };

          return (
            <Button variant="outlined" color="error" onClick={handleClick}>
              Delete
            </Button>
          );
        }
      }
    }
  ];

  const options = {
    filter: true,
    editable: true,
    filterType: 'dropdown',
    selectableRows: false
  };

  return (
    <>
      <Typography sx={{ mt: 5, ml: 4 }}>
        1. Upload the file you want displayed on the Portal <b>Home Page</b> <em>or</em> <b>Resources page</b>.
      </Typography>
      <Typography sx={{ mt: 2, ml: 4 }}>
        2. After uploading a file, click <b>Edit</b> to add/update Title, Thumbnail image, and placement for each
        Resource.
      </Typography>
      <Typography sx={{ mt: 2, ml: 4 }}>
        3. If you want to restrict a Resource to specific clients, select those clients by editing the Resource.
      </Typography>
      <Typography sx={{ mt: 2, ml: 4 }}>
        4. When editing is complete, set the status to <b>Active</b> to make the Resource visible in the Portal.
      </Typography>
      <Typography sx={{ mt: 2, ml: 4 }}>
        5. To hide a Resource, either delete it or set its status to <b>Inactive</b>.
      </Typography>
      <Box sx={{ my: 5, ml: 3 }}>
        <InputFileUpload onFileSelect={handleSelectedFile} />
      </Box>
      <MUIDataTable
        title={<h2 style={{ color: Colors.Text }}>Uploaded Resources</h2>}
        editable={true}
        data={tableData}
        columns={columns}
        options={options}
      />
      <ConfirmationDialog
        isOpen={confirmationDialogOpen}
        title="Delete File?"
        message={
          <>
            <Typography sx={{ mb: 3 }}>
              Are you sure you want to permanently delete this file from the storage?
            </Typography>
            <Typography sx={{ mb: 5 }}>Once deleted the file won't be accessible for participants anymore.</Typography>
          </>
        }
        onConfirmBtn={handleDelete}
        onCancelBtn={() => setConfirmationDialogOpen(false)}
      />
      <UsagesModal
        data={openUsagesModal}
        onClose={() => setOpenUsagesModal({ open: false, sessionList: [], filename: '' })}
      />
      <EditLearningTool clients={clients} open={openEditLearningTool} fileObject={fileObject} onClose={handleClose} />
    </>
  );
}