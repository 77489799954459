import * as React from 'react';
import MUIDataTable from 'mui-datatables';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { fetchResponsesByTaskId, getUserPopulatedTaskResponses } from '../../../../../redux/actions/tasks';
import { Colors, TaskName } from '../../../../../enums/enums';
import firstAssessmentColumns from './dtos/FirstAssessment';
import midCohortGroupColumns from './dtos/MidCohortGroupSurvey';
import finalGroupColumns from './dtos/FinalGroupSurvey';
import { UsersTaskResponses } from './enums/UsersTaskResponses';
import pilotGroupColumns from './dtos/PilotGroupSurvey';
import oneOnOneFinalColumns from './dtos/OneOnOneFinalSurvey';
import oneOnOneMidCohortColumns from "./dtos/OneOnOneMidCohortSurvey";

interface ITaskResponse {
  groupId: string;
  taskName: string;
  taskId: string;
}

const TaskResponse: React.FC<ITaskResponse> = ({ groupId, taskName, taskId }) => {
  const dispatch = useDispatch();

  const taskResponses: UsersTaskResponses[] = useSelector(getUserPopulatedTaskResponses);

  React.useEffect(() => {
    dispatch(fetchResponsesByTaskId(taskId));
  }, [dispatch]);

  const data = taskResponses;

  let tableColumnsFound;
  switch (taskName) {
    case TaskName.FirstSessionAssessment:
      tableColumnsFound = firstAssessmentColumns;
      break;
    case TaskName.MidCohortGroupAssessment:
      tableColumnsFound = midCohortGroupColumns;
      break;
    case TaskName.FinalGroupAssessment:
      tableColumnsFound = finalGroupColumns;
      break;
    case TaskName.PilotGroupCoachingAssessment:
      tableColumnsFound = pilotGroupColumns;
      break;
    case TaskName.OneOnOneFinalSurvey:
      tableColumnsFound = oneOnOneFinalColumns;
      break;
      case TaskName.OneOnOneMidCohortSurvey:
      tableColumnsFound = oneOnOneMidCohortColumns;
      break;
  }

  const options = {
    filter: true,
    selectableRows: 'none',
    // pagination: false,
    download: true,
    print: true,
    // search: false,
    viewColumns: true,
    stickyHeader: true
  };

  return (
    <div style={{ padding: '8px', width: '100%' }}>
      <Typography
        sx={{ mb: 2, mr: 2, fontWeight: 'bold', fontSize: '1.2rem', color: Colors.ButtonGreen }}
        textAlign="left"
      >
        {data?.length === 1 ? `${data?.length} response submitted` : `${data?.length} responses submitted`}
      </Typography>
      <MUIDataTable data={data} columns={tableColumnsFound} options={options} />
    </div>
  );
};

export default TaskResponse;