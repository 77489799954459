import React from 'react';
import { Rating, Typography } from '@mui/material';

const oneOnOneMidCohortColumns = [
  {
    name: 'date',
    label: 'Date',
    options: {
      sort: true,
      customBodyRender: (v, x) => <Typography fontSize="small">{v?.substring(0, 10)}</Typography>,
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap'
        }
      })
    }
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      sort: true,
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap'
        }
      }),
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography sx={{ fontWeight: 'bold', color: 'green' }}>{v}</Typography>
            <Typography fontSize="small">{x?.rowData[2]}</Typography>
            <Typography fontSize="small">
              {x?.rowData[3]} at {x?.rowData[4]}
            </Typography>
          </>
        );
      }
    }
  },
  {
    name: 'email',
    label: '',
    options: {
      display: false
    }
  },
  {
    name: 'title',
    label: '',
    options: {
      display: false
    }
  },
  {
    name: 'company',
    label: '',
    options: {
      display: false
    }
  },
  {
    name: 'ratingCoach',
    label: 'Coach Rating*',
    options: {
      hint: 'How satisfied are you with your overall experience with your coach so far?',
      sort: true,
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap'
        }
      }),
      customBodyRender: (v, x) => {
        return (
          <>
            <Rating sx={{ ml: -1 }} name="read-only" value={parseInt(v)} readOnly />
          </>
        );
      }
    }
  },
  {
    name: 'ratingExperience',
    label: 'Overall experience*',
    options: {
      sort: true,
      hint: 'How satisfied are you so far with the overall experience?',
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap'
        }
      }),
      customBodyRender: (v, x) => {
        return (
          <>
            <Rating sx={{ ml: -1 }} name="read-only" value={parseInt(v)} readOnly />
          </>
        );
      }
    }
  },
  {
    name: 'coachEffectiveness',
    label: 'Coach Effectiveness*',
    options: {
      sort: true,
      hint: 'Do you feel your coach understands your goals and challenges effectively?',
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap'
        }
      }),
      customBodyRender: (v, x) => {
        return (
          <>
            <Rating sx={{ ml: -1 }} name="read-only" value={parseInt(v)} readOnly />
          </>
        );
      }
    }
  },
  {
    name: 'communication',
    label: 'Communication*',
    options: {
      sort: true,
      hint: 'How would you rate the level of communication and feedback between you and your coach?',
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap'
        }
      }),
      customBodyRender: (v, x) => {
        return (
          <>
            <Rating max={4} sx={{ ml: -1 }} name="read-only" value={parseInt(v)} readOnly />
          </>
        );
      }
    }
  },
  {
    name: 'sessionsImprovement',
    label: 'Sessions Improvement',
    options: {
      hint: 'Is there anything specific you would like to see improved or adjusted in your coaching sessions?',
      customBodyRender: (v, x) => {
        return (
          <>
            <Typography fontSize="small">{v}</Typography>
          </>
        );
      }
    }
  }
];

export default oneOnOneMidCohortColumns;