import React, { useEffect, useState } from 'react';
import { Chip, MenuItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import CoachingSessionFilter from './CoachingSessionFilter';
import MaterialTable from '../../../../shared/MaterialTableIcons';
import CreateCoachingSessionEventForm from '../calendar/CreateCoachingSessionEventForm';
import api from '../../../../../services/api';
import { ICoachingSession } from '../coaching.models';
import { toast } from 'react-toastify';
import {
  deleteCoachingSession,
  fetchAllCoachingSessions,
  getCoachingSessions
} from '../../../../../redux/actions/coachingSessions';
import { fetchCurrentGroups, getGroups } from '../../../../../redux/actions/groups';
import { SessionStage, SessionStatus } from '../coaching.enums';
import { InputLabel, Select, Typography, TextField, Tooltip, Box, Button, Paper } from '@mui/material';
import { fetchContentFiles, getFiles } from '../../../../../redux/actions/content';
import { Colors } from '../../../../../enums/enums';
import { fetchTasks, getTasks } from '../../../../../redux/actions/tasks';
import { useParams } from 'react-router-dom';
import GoogleCalendarIcon from '../../../../../assets/google-calendar.svg';
import { SESSION_DURATIONS } from '../coaching.constants';
import { fetchCurrentUsers, getActiveUsers } from '../../../../../redux/actions/users';
import CreateIndividualSessionEventForm from '../calendar/CreateIndividualSessionEventForm';

const statusColors = {
  CONFIRMED: Colors.Active,
  COMPLETED: 'lightgrey',
  CANCELLED: Colors.Inactive
};

const CoachingSessionsListFiltered: React.FC = () => {
  const { groupName } = useParams<{ groupName: string }>();
  const { groupId } = useParams<{ groupId: string }>();
  const dispatch = useDispatch();
  const sessions = useSelector(getCoachingSessions);
  const groups = useSelector(getGroups);
  const users = useSelector(getActiveUsers);
  const tasks = useSelector(getTasks);
  const uploadedFiles = useSelector(getFiles);
  const [selectedGroup, setSelectedGroup] = useState<{
    id: string;
    description: string;
  }>({ id: groupId, description: groupName });
  const [googleEventExists, setGoogleEventExists] = useState(false);
  const [openDialogSession, setOpenDialogSession] = useState(false);
  const [openIndividualDialogSession, setOpenIndividualDialogSession] = useState(false);
  const [sessionsData, setSessionsData] = useState(sessions);

  useEffect(() => {
    const loadData = async () => {
      await dispatch(fetchContentFiles());
      await dispatch(fetchAllCoachingSessions());
      await dispatch(fetchCurrentGroups());
      await dispatch(fetchCurrentUsers());
      await dispatch(fetchTasks());
    };
    loadData();
  }, [dispatch]);

  useEffect(() => {
    setSessionsData(sessions?.filter((session) => session.groupId === groupId));
  }, [sessions, groupId]);

  useEffect(() => {
    if (selectedGroup) {
      setSessionsData(sessions?.filter((session) => session.groupId === selectedGroup.id));
    } else {
      setSessionsData(sessions);
    }
  }, [selectedGroup]);

  const getGroupName = (id: string): string => {
    let groupName = '';
    const group = groups?.filter((group) => group.id === id)[0];
    if (group) {
      groupName = group.description;
    }
    return groupName;
  };

  const getLeaderName = (groupId: string): string => {
    let leaderName = '';
    const group = groups.find((group) => group.id === groupId);
    if (group) {
      leaderName = users?.find((u) => u.id === group?.leaderId)?.name;
    }
    return leaderName;
  };

  const getFileName = (id: string) => {
    let filename = '';
    const file = uploadedFiles?.filter((file) => file.id === id)[0];
    if (file) {
      const originalFileName = file.filename?.split('-')?.slice(1)?.join('-');
      const originalFileNameWithoutDash = file.filename?.replace(/^\d+/, '');
      filename = originalFileName
        ? originalFileName
        : originalFileNameWithoutDash
        ? originalFileNameWithoutDash
        : file.filename;
    }
    return filename;
  };

  const getTaskData = (id: string): { taskName: string; description: string } => {
    let data = { taskName: '', description: '' };
    const task = tasks.find((task) => task.id === id);
    if (task) {
      data.taskName = task.taskName;
      data.description = task.description;
    }
    return data;
  };

  const getFileUrl = (id: string): string => {
    let fileUrl = '';
    const file = uploadedFiles.filter((file) => file.id === id)[0];
    if (file) {
      fileUrl = file.azureUrl;
    }
    return fileUrl;
  };

  const deleteConfirmationMessage = (
    <Box sx={{ m: 3 }}>
      <Typography sx={{ color: 'darksalmon', fontWeight: 'bold', fontSize: '1.3rem' }}>
        Are you sure you want to delete this session? This action cannot be undone.
      </Typography>
      {googleEventExists && (
        <Typography sx={{ color: Colors.TextElevated, fontSize: '1.2rem', fontWeight: 'bold' }}>
          Google Calendar event will also be removed.
        </Typography>
      )}
    </Box>
  );

  const columns = [
    {
      field: 'groupId',
      title: 'Cohort',
      render: (v) => (
        <span>
          {getGroupName(v)}, <span style={{ color: Colors.ButtonGreen }}>({getLeaderName(v)})</span>
        </span>
      ),
      defaultGroupOrder: 0,
      options: {
        customBodyRender: (v) => <Typography>{getGroupName(v)}</Typography>
      }
    },
    {
      field: 'googleCalendarId',
      title: '',
      editable: 'never',
      render: (v) => {
        if (v?.googleCalendarId) {
          setGoogleEventExists(true);
        }
        const eventLinkValue = v?.googleCalendarEventHtml;
        return (
          v?.googleCalendarId && (
            <Button sx={{ ml: -3, mr: -2 }} size="small" onClick={() => navigator.clipboard.writeText(eventLinkValue)}>
              <Tooltip title="Click to copy Google event link">
                <img src={GoogleCalendarIcon} alt="icon" style={{ maxWidth: '40px' }} />
              </Tooltip>
            </Button>
          )
        );
      }
    },
    {
      field: 'date',
      title: 'Date',
      type: 'date',
      render: (v) => {
        if (v) {
          const parsedData = parseISO(v.date);
          return (
            <Typography sx={{ minWidth: '200px', ml: -3, mr: -3 }}>
              {format(parsedData, 'MMM, dd, yyyy H:mm a').toString()}
            </Typography>
          );
        }
      },
      editComponent: (props) => (
        <>
          <InputLabel shrink>Date</InputLabel>
          <TextField
            id="datetime-local"
            variant="outlined"
            type="datetime-local"
            color="success"
            value={props.value ? format(parseISO(props.value), 'yyyy-MM-dd HH:mm') : props.value}
            onChange={(e) => props.onChange(e.target.value)}
            style={{ height: '56px' }}
          />
        </>
      )
    },
    {
      field: 'topic',
      title: 'Session Topic',
      render: (v) => {
        return <Typography sx={{ minWidth: '250px' }}>{v.topic}</Typography>;
      },
      editComponent: (props) => (
        <>
          <InputLabel shrink>Session Topic</InputLabel>
          <TextField
            value={props.value}
            variant="outlined"
            color="success"
            placeholder="Add session topic"
            onChange={(e) => props.onChange(e.target.value)}
            style={{ height: '56px', minWidth: '250px' }}
          />
        </>
      )
    },
    {
      field: 'linkedFileId',
      title: 'Attached Content',
      render: (v) => {
        return (
          <a href={getFileUrl(v?.linkedFileId)} target="_blank">
            <Typography sx={{ minWidth: '200px' }}>{getFileName(v?.linkedFileId)}</Typography>
          </a>
        );
      },
      editComponent: (props) => (
        <>
          <InputLabel shrink>Content</InputLabel>
          <Select
            variant="outlined"
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            style={{ height: '56px', minWidth: '200px' }}
          >
            <MenuItem style={{ backgroundColor: 'lightgray', color: 'white' }} key="1" value={null}>
              No attachments
            </MenuItem>
            {uploadedFiles.map((file) => {
              const originalFileName = file.filename?.split('-')?.slice(1)?.join('-');
              const originalFileNameWithoutDash = file.filename?.replace(/^\d+/, '');

              const displayName = originalFileName
                ? originalFileName
                : originalFileNameWithoutDash
                ? originalFileNameWithoutDash
                : file?.filename;

              return (
                <MenuItem key={file?.id} value={file?.id}>
                  {displayName}
                </MenuItem>
              );
            })}
          </Select>
        </>
      )
    },
    {
      field: 'linkedTaskId',
      title: 'Assessment / Task',
      render: (v) => {
        const taskObj = getTaskData(v?.linkedTaskId);
        return (
          <Tooltip title={taskObj?.description}>
            <Typography sx={{ minWidth: '250px' }}>{taskObj?.taskName}</Typography>
          </Tooltip>
        );
      },
      editComponent: (props) => {
        return (
          <>
            <InputLabel shrink>Assessment / Task</InputLabel>
            <Select
              variant="outlined"
              value={props?.value}
              // disable task selection for Individual sessions
              disabled={props.rowData?.stage === SessionStage.Individual}
              onChange={(e) => props.onChange(e.target.value)}
              style={{ height: '56px', minWidth: '250px' }}
            >
              <MenuItem style={{ backgroundColor: 'lightgray', color: 'white' }} key="1" value={null}>
                Remove attached task
              </MenuItem>
              {tasks
                .filter((task) => task.groupId === props?.rowData?.groupId)
                .map((task) => (
                  <MenuItem key={task.id} value={task.id}>
                    {task.taskName}
                  </MenuItem>
                ))}
            </Select>
          </>
        );
      }
    },
    {
      field: 'stage',
      title: 'Stage',
      editable: 'never',
      render: (rowData) => {
        const stage = rowData?.stage;
        const participantName = users?.find((u) => u.id === rowData?.participantId)?.name;
        return (
          <Box sx={{ minWidth: '180px', ml: -2, mr: -4 }}>
            <Typography gutterBottom>{stage === SessionStage.MeetAndGreet ? 'First Session' : stage}</Typography>
            {rowData?.stage === SessionStage.Individual && (
              <Typography sx={{ color: Colors.ButtonGreen }}>{participantName}</Typography>
            )}
          </Box>
        );
      }
    },
    {
      field: 'duration',
      title: 'Duration',
      render: (v) => {
        const duration = v?.duration ? v?.duration : 30;
        return (
          <Typography>
            {duration == 60
              ? '1 hour'
              : duration == 90
              ? '1.5 hours'
              : duration == 105
              ? '1 h 45 mins'
              : duration == 120
              ? '2 hours'
              : `${duration} mins`}
          </Typography>
        );
      },
      editComponent: (props) => {
        return (
          <>
            <InputLabel shrink>Session Duration</InputLabel>
            <Select
              variant="outlined"
              value={props?.value}
              onChange={(e) => props.onChange(e.target.value)}
              style={{ height: '56px', minWidth: '180px' }}
            >
              {SESSION_DURATIONS.map((duration) => (
                <MenuItem key={duration.id} value={duration.value}>
                  {duration.label}
                </MenuItem>
              ))}
            </Select>
          </>
        );
      }
    },
    {
      field: 'status',
      title: 'Status',
      render: (v) => {
        return <Chip style={{ backgroundColor: statusColors[v.status] }} label={v?.status} />;
      },
      editComponent: (props) => (
        <>
          <InputLabel shrink>Status</InputLabel>
          <Select variant="outlined" value={props.value} onChange={(e) => props.onChange(e.target.value)}>
            <MenuItem value={SessionStatus.Confirmed}>{SessionStatus.Confirmed}</MenuItem>
            <MenuItem value={SessionStatus.Completed}>{SessionStatus.Completed}</MenuItem>
            <MenuItem value={SessionStatus.Cancelled}>{SessionStatus.Cancelled}</MenuItem>
          </Select>
        </>
      )
    },
    {
      field: 'participantId',
      title: '',
      hidden: true
    }
  ];

  return (
    <div style={{ padding: '8px' }}>
      <CreateCoachingSessionEventForm
        groups={groups}
        sessions={sessions}
        openDialog={openDialogSession}
        onClose={setOpenDialogSession}
      />
      <CreateIndividualSessionEventForm
        users={users}
        groups={groups}
        sessions={sessions}
        openIndividualDialog={openIndividualDialogSession}
        onClose={setOpenIndividualDialogSession}
      />
      <Paper
        elevation={3}
        sx={{
          mb: 2,
          mt: -1,
          pb: 2,
          pt: 1,
          px: 2
        }}
      >
        <CoachingSessionFilter
          selectedGroup={selectedGroup}
          filterGroup={setSelectedGroup}
          setOpenDialogSession={setOpenDialogSession}
          setOpenIndividualDialogSession={setOpenIndividualDialogSession}
        />
      </Paper>

      <MaterialTable
        title={
          <h2 style={{ color: Colors.ButtonGreen }}>{selectedGroup ? selectedGroup?.description : 'All'} Sessions</h2>
        }
        columns={columns}
        data={sessionsData}
        defaultExpanded={true}
        localization={{
          body: { editRow: { deleteText: deleteConfirmationMessage } }
        }}
        editable={{
          /**
           * Commenting this approach as it breaks selected Groups Expansion.
           * Found temporary solution bellow.
           *
          onRowUpdate: (newData, oldData) =>
              new Promise<void>((resolve, reject) => {
                const id = newData.id;
                const status = newData.status;
                const date = parseISO(newData.date);
                setTimeout(() => {
                  const dataUpdate = [...data];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = newData;
                  setData([...dataUpdate]);
                  dispatch(updateSession({ id, status, date }));
                  resolve();
                }, 1000);
              }),
          */
          /**
           * This is new temporary solution that updates the value in the MuiTable
           * without breaking expanded groups.
           */
          onRowUpdate: (newData, oldData) =>
            new Promise<void>((resolve, reject) => {
              const id = newData.id;
              const status = newData.status;
              const date = parseISO(newData?.date);
              const topic = newData.topic;
              const linkedFileId = newData.linkedFileId;
              const linkedTaskId = newData.linkedTaskId;
              const duration = newData.duration;
              const googleCalendarId = oldData?.googleCalendarId;
              const googleCalendarEventHtml = oldData?.googleCalendarEventHtml;

              api
                .patch<ICoachingSession>('/coaching-sessions', {
                  id,
                  status,
                  date,
                  topic,
                  linkedFileId,
                  linkedTaskId,
                  duration,
                  googleCalendarId,
                  googleCalendarEventHtml
                })
                .then((response) => {
                  const updatedSession = response.data;
                  const newDate = updatedSession.date;
                  const newStatus = updatedSession.status;
                  const newTopic = updatedSession.topic;
                  const newLinkedFileId = updatedSession.linkedFileId;
                  const newLinkedTaskId = updatedSession.linkedTaskId;
                  const newDuration = updatedSession.duration;
                  const newGoogleCalendarId = updatedSession?.googleCalendarId;
                  const newGoogleCalendarEventHtml = updatedSession?.googleCalendarEventHtml;

                  const fromSessions = sessions?.find((s) => s.id === id);
                  if (fromSessions) {
                    fromSessions.date = newDate;
                    fromSessions.status = newStatus;
                    fromSessions.topic = newTopic;
                    fromSessions.linkedFileId = newLinkedFileId;
                    fromSessions.linkedTaskId = newLinkedTaskId;
                    fromSessions.duration = newDuration;
                    fromSessions.googleCalendarId = newGoogleCalendarId;
                    fromSessions.googleCalendarEventHtml = newGoogleCalendarEventHtml;
                  }
                  const fromData = sessionsData?.find((s) => s.id === id);
                  if (fromData) {
                    fromData.date = newDate;
                    fromData.status = newStatus;
                    fromData.topic = newTopic;
                    fromData.linkedFileId = newLinkedFileId;
                    fromData.linkedTaskId = newLinkedTaskId;
                    fromData.duration = newDuration;
                    fromData.googleCalendarId = newGoogleCalendarId;
                    fromData.googleCalendarEventHtml = newGoogleCalendarEventHtml;
                  }
                  toast.success('Successfully updated session');
                  if (status === SessionStatus.Cancelled && linkedTaskId) {
                    toast.success('Session Task is removed');
                  }
                  resolve();
                })
                .catch((error) => {
                  console.error('Error updating session', error);
                  toast.error(
                    `Error updating session.\nMessage: ${
                      error.response?.data?.message || error.request || error.message
                    }`
                  );
                  reject();
                });
            }),
          onRowDelete: (oldData) =>
            new Promise<void>((resolve, reject) => {
              const sessionId = oldData.id;
              const dataDelete = [...sessionsData];
              const index = oldData.tableData.id;

              api
                .delete(`/coaching-sessions/${sessionId}`)
                .then((deleteResponse) => {
                  const { data, statusText } = deleteResponse;
                  const deletedFromGoogle = data?.deleteResponseGoogle;
                  const deleteResultDb = data?.deleteResultDb;
                  const googleEventExists = data?.googleEventExists;

                  if (deleteResultDb?.affected === 1) {
                    const toastMessageGoogle = deletedFromGoogle
                      ? ' and from Google Calendar'
                      : ' but NOT from Google Calendar';
                    const messageToAdd = googleEventExists ? toastMessageGoogle : '';
                    dataDelete.splice(index, 1);
                    setSessionsData([...dataDelete]);
                    setGoogleEventExists(false);
                    toast.success('Successfully deleted session from database' + messageToAdd);
                    resolve();
                  } else {
                    toast.error(`Error deleting session.\nMessage: ${statusText}`);
                  }
                })
                .catch((error) => {
                  console.error('Error deleting session', error);
                  toast.error(
                    `Error deleting session.\nMessage: ${
                      error.response?.data?.message || error.request || error.message
                    }`
                  );
                });
            })
        }}
        actions={[]}
        options={{
          grouping: true,
          actionsColumnIndex: -1,
          pageSize: 15,
          pageSizeOptions: [15, 25, 50]
        }}
      />
    </div>
  );
};

export default CoachingSessionsListFiltered;