import * as React from 'react';
import api from '../../../services/api';
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { useState } from 'react';
import { InputFileUpload } from '../../shared/input-file-upload/InputFileUpload';
import { format, parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFile, fetchContentFiles, getFiles } from '../../../redux/actions/content';
import { ConfirmationDialog } from '../../dialogs/ConfirmationDialog';
import { Colors } from '../../../enums/enums';
import { toast } from 'react-toastify';
import UsagesModal from './UsagesModal';
import WarningIcon from '@mui/icons-material/Warning';

interface FileUploadResponse {
  id: string;
  filename: string;
  url: string;
  createdAt: string;
}

export default function SessionContent() {
  const dispatch = useDispatch();
  const uploadedFiles = useSelector(getFiles);
  const [tableData, setTableData] = useState([]);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [selectedFileId, setSelectedFileId] = React.useState(null);
  const [usages, setUsages] = React.useState(null);
  const [openUsagesModal, setOpenUsagesModal] = React.useState({ open: false, sessionList: [], filename: '' });

  React.useEffect(() => {
    dispatch(fetchContentFiles());
  }, [dispatch]);

  React.useEffect(() => {
    setTableData(uploadedFiles?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
  }, [uploadedFiles]);

  const handleSelectedFile = (files: FileList): void => {
    if (files?.length) {
      const file = files?.item(0);
      console.log('File selected', file);

      const formData = new FormData();
      formData?.append('file', file);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      api.post<FileUploadResponse>(`/content/file`, formData, config).then(({ data }) => {
        dispatch(fetchContentFiles());
        console.log('API response on file upload: ', data);
        toast.success('Successfully uploaded file');
      });
    }
  };

  const handleDelete = () => {
    dispatch(deleteFile(selectedFileId, usages));
    setConfirmationDialogOpen(false);
    setUsages(null);
  };

  const columns = [
    {
      name: 'id',
      options: {
        display: false
      }
    },
    {
      name: 'filename',
      label: 'Name',
      options: {
        customBodyRender: (v, x) => {
          // to not display the timestamp that is added to each file to avoid duplicated filenames
          const originalFileName = v?.split('-')?.slice(1)?.join('-');
          const originalFileNameWithoutDash = v?.replace(/^\d+/, '');
          return (
            <Typography>
              {originalFileName ? originalFileName : originalFileNameWithoutDash ? originalFileNameWithoutDash : v}
            </Typography>
          );
        }
      }
    },
    {
      name: 'azureUrl',
      label: 'URL',
      options: {
        customBodyRender: (v, x) => (
          <Tooltip title="View file" arrow placement="top">
            <a href={v} target="_blank">
              View File
            </a>
          </Tooltip>
        )
      }
    },
    {
      name: 'sessions',
      label: 'Usages',
      options: {
        customBodyRender: (v, x) => {
          return (
            <Tooltip title="View usages" arrow placement="top">
              <Button
                disabled={!v.length}
                onClick={() => {
                  setUsages(v);
                  setOpenUsagesModal({ open: true, sessionList: v, filename: x?.rowData[1] });
                }}
              >
                {v?.length}
              </Button>
            </Tooltip>
          );
        }
      }
    },
    {
      name: 'mimeType',
      label: 'Type',
      options: {
        customBodyRender: (v, x) => <Typography>{v}</Typography>
      }
    },
    {
      name: 'size',
      label: 'Size',
      options: {
        customBodyRender: (v, x) => {
          const k = v > 0 ? Math.floor(Math.log2(v) / 10) : 0;
          const rank = (k > 0 ? 'KMGT'[k - 1] : '') + 'b';
          const count = Math.floor(v / Math.pow(1024, k));
          return <Typography>{count + rank}</Typography>;
        }
      }
    },
    {
      name: 'createdAt',
      label: 'Uploaded on',
      options: {
        sort: true,
        customBodyRender: (value) => {
          const parsedData = parseISO(value);
          return <Typography>{format(parsedData, 'MMM dd, yyyy').toLocaleString()}</Typography>;
        }
      }
    },
    {
      name: 'id',
      label: 'Actions',
      options: {
        customBodyRender: (value, x) => {
          return (
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                setSelectedFileId(value);
                setUsages(x?.rowData[3]);
                setConfirmationDialogOpen(true);
              }}
            >
              Delete
            </Button>
          );
        }
      }
    }
  ];

  const options = {
    filter: true,
    filterType: 'dropdown',
    selectableRows: false
  };

  return (
    <>
      <Typography sx={{ mt: 5, ml: 3 }}>
        Upload files you’d like to include as session content for cohort participants.
      </Typography>
      <Box sx={{ my: 5, ml: 3 }}>
        <InputFileUpload onFileSelect={handleSelectedFile} />
      </Box>

      <MUIDataTable
        title={<h2 style={{ color: Colors.Text }}>Uploaded Files</h2>}
        editable={true}
        data={tableData}
        columns={columns}
        options={options}
      />

      <ConfirmationDialog
        isOpen={confirmationDialogOpen}
        title="Delete File?"
        message={
          <>
            <Typography sx={{ mb: 3 }}>
              Are you sure you want to permanently delete this file from the storage?
            </Typography>
            {usages && usages?.length > 0 && (
              <>
                <Stack direction="row">
                  <WarningIcon color="error" />
                  <Typography color="error" sx={{ mb: 3, ml: 1 }}>
                    This file is attached to {usages?.length} {usages?.length === 1 ? 'session' : 'sessions'}.
                  </Typography>
                </Stack>
                <Typography sx={{ mb: 5 }}>
                  Once deleted the file won't be accessible for participants anymore.
                </Typography>
              </>
            )}
          </>
        }
        onConfirmBtn={handleDelete}
        onCancelBtn={() => setConfirmationDialogOpen(false)}
      />
      <UsagesModal
        data={openUsagesModal}
        onClose={() => setOpenUsagesModal({ open: false, sessionList: [], filename: '' })}
      />
    </>
  );
}